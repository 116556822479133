// OpenAIPrompt.js
const GeneratedPrompt = ({ prompt }) => {
  return (
    <div style={{ textAlign: 'left', marginTop: '20px', padding: '10px', backgroundColor: '#f9f9f9', borderRadius: '5px' }}>
      <h3 className="font-bold">Generated Agent Prompt</h3>
      <p>{prompt.role}</p><br/>
      <p>{prompt.instruction}</p>
      <ul className="list-disc px-4">
        {prompt.objectives.map((objective, index) => <li key={index}>{objective}</li>)}
      </ul>
      <br/>
      <p>{prompt.guardrail}</p>
      <ul className="list-disc px-4">
        {prompt.guidelines.map((guideline, index) => <li key={index}>{guideline}</li>)}
      </ul>
    </div>
  );
};

export default GeneratedPrompt;
