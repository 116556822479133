// CrayonInput.js
import React, { useState } from 'react';
import { useNavigate } from 'react-router-dom';
import Logo from './elements/Logo.js'
import { getClarifyingQuestions } from './openaiApi';
import Spinner from './elements/Spinner.js';

const CrayonInput = () => {
  // Minimum required tokens from input for meaningful results.
  const MIN_REQUIRED_TOKENS = 30;

  const [inputText, setInputText] = useState('');
  const [tokenCount, setTokenCount] = useState(0);
  const [isValidTokens, setIsValidTokens] = useState(false);
  const [isLoading, setIsLoading] = useState(false);
  const navigate = useNavigate();
  const navigateToPrompt = () => {
    navigate('/prompt', { state: { inputText } });
  };

  const navigateToQuestions = async () => {
    try {
      setIsLoading(true);
      const questions = await getClarifyingQuestions(inputText);
      const parsedQuestions = JSON.parse(questions.response).response;
      // Take only the first 3 questions
      const limitedQuestions = parsedQuestions.slice(0, 3);
      navigate('/questions', { 
        state: { 
          questions: limitedQuestions,
          originalInput: inputText 
        } 
      });
    } catch (error) {
      // Fallback to direct prompt navigation if questions fail
      navigateToPrompt();
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && isValidTokens) {
      e.preventDefault();
      navigateToQuestions();
    }
  };

  const handleInputChange = (e) => {
    const text = e.target.value;
    setInputText(text);
    setTokenCount(text.length);
    setIsValidTokens(text.length >= MIN_REQUIRED_TOKENS);
  };

  return (
    <div className='container'>
      <div style={{ flex: 3, paddingRight: '20px' }}>
        <Logo />
        <div className="progress-bar">
          <div className="progress-dot"></div>
          <div className="progress-line"></div>
          <div className="progress-dot empty"></div>
          <div className="progress-line"></div>
          <div className="progress-dot empty"></div>
        </div>
        <h3>What do you want your AI to do?</h3>
        <textarea
          className='text-area'
          placeholder='Describe what you want your AI agent to do:'
          style={{
            width: '100%',
            height: '100px',
            fontSize: '1rem',
            padding: '10px',
            boxSizing: 'border-box',
            border: '1px solid #ccc',
            borderRadius: '5px'
          }}
          value={inputText}
          onChange={handleInputChange}
          onKeyDown={handleKeyDown}
        />
        {isLoading && <Spinner />}
          <p className="word-count" style={{ padding: "0px", marginBottom: '0px', color: !isValidTokens ? '#8B0000' : '#00A300', textAlign: "right"}}>
            { !isValidTokens ?
                <strong>
                 {Math.max(MIN_REQUIRED_TOKENS - tokenCount, 0)} characters required
                </strong> :
                'Ready to go!'
            }
          </p>
        <div className="footer">
          <a href="https://platform.openai.com/docs/examples" className="inspiration">
            💡 See Examples
          </a>
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <button
              onClick={navigateToQuestions}
              disabled={!isValidTokens || isLoading}
              style={{
                cursor: isValidTokens || !isLoading? 'pointer' : 'not-allowed'
              }}
              className="next-button">
              Next ▶
            </button>
            <small style={{ marginTop: '4px', color: '#666' }}>or Press Enter to continue</small>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrayonInput;
