// CrayonPromptComparison.js
import React, { useEffect, useState } from 'react';
import { Crown, Loader } from 'lucide-react';
import { useNavigate } from 'react-router-dom';
import { getSimilarPrompt, getOpenAIResponse } from './openaiApi';
import Logo from './elements/Logo.js';

import { generateClient } from 'aws-amplify/data'
const client = generateClient({
  authMode: 'apiKey',
});

// TODO: add logging for scoring table.
const createChatGPTLog = async (
  agentPrompt) => {
  await client.models.ChatGPT.create({
    useragent: navigator.userAgent,
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    agent_prompt: agentPrompt,
  })
};

const createPromptLog = async (prompt, response, openaiRequest, openaiResponse) => {
  await client.models.Prompt.create({
    useragent: navigator.userAgent,
    ip_address: '',
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    prompt: prompt,
    response: response,
    openai_request: openaiRequest,
    openai_response: openaiResponse
  })
};

const CrayonPromptComparison = ({ inputText, generatedPromptText, inputTextResponse }) => {
  const [isLoading, setIsLoading] = useState(true);
  const [preferredPair, setPreferredPair] = useState(1); // 1 or 2

  const navigate = useNavigate();

  // Sample data - in practice, this would come from props or an API
  const [pairs, setPairs] = useState({
    pair1: {
      prompt: generatedPromptText,
      response: "",
    },
    pair2: {
      prompt: inputText,
      response: inputTextResponse,
    }
  });

  const updatePair = (selectedPair, newPair) => {
    setPairs(prev => ({
      ...prev,
      [selectedPair === 1 ? 'pair1' : 'pair2']: newPair
    }));
  }

  // Set generated response on initialization.
  useEffect(() => {
    setIsLoading(true);
    async function initializePrompt() {
      const { response: generatedPromptResponse } = await getOpenAIResponse(generatedPromptText, "");
      const newPair = {
        prompt: generatedPromptText,
        response: generatedPromptResponse
      }
      updatePair(1, newPair);
      setIsLoading(false);
    }
    initializePrompt();
  }, [generatedPromptText]);

  const getPreferredPair = () => {
    return preferredPair === 1 ? pairs.pair1 : pairs.pair2
  }

  const getUnpreferredPair = () => {
    return preferredPair === 1 ? pairs.pair2 : pairs.pair1
  }

  const copyToClipboardAndGoToChatGPT = () => {
    if (getPreferredPair().prompt.length === 0) return;

    // Copy the last message to the clipboard
    createChatGPTLog(getPreferredPair().prompt);
    navigator.clipboard.writeText(getPreferredPair().prompt);
    alert('Prompt copied to clipboard!\nAfter clicking OK, a ChatGPT will be opened in a new tab.\nPaste your new prompt to test!');
    const newTab = window.open('https://www.chatgpt.com', '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      alert("Failed to open tab...");
    }
  };


  const handlePreferredSelection = async (selectedPair) => {
    if (isLoading) return;

    setIsLoading(true);
    setPreferredPair(selectedPair);

    try {
      // Updated to handle the openAI request and response
      const { response: newPrompt, rawRequest: openaiRequest, rawResponse: openaiResponse } = await getSimilarPrompt(
        getPreferredPair().prompt,
        getUnpreferredPair().prompt
      );

      const {response: newResponse} = await getOpenAIResponse(newPrompt, "");

      // Update the non-preferred pair with new data
      const newPair = {
        prompt: newPrompt,
        response: newResponse
      };
      updatePair(selectedPair === 1 ? 2 : 1, newPair);

      // Updated createPromptLog to include openAI data
      await createPromptLog(
        getPreferredPair().prompt,
        newPrompt,
        openaiRequest,
        openaiResponse
      );
    } catch (error) {
      console.error('Error loading new prompt:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const PairContainer = ({ pairNumber, data, isPreferred }) => (
    <div
      className={`flex-1 p-6 rounded-lg ${isPreferred
          ? 'border-2 border-yellow-400 bg-yellow-50'
          : 'border-2 border-gray-200 bg-white'
        }`}
    >
      <div className="space-y-6">
        <div>
          <div className="flex justify-between">
            <div className="mb-4"><h3 className="text-lg font-semibold mb-2">Prompt</h3></div>
            {isPreferred && (
              <div className="flex items-center gap-2 justify-end mb-4 text-yellow-600">
                <Crown className="w-5 h-5" />
                <span className="font-medium">Current Champion</span>
              </div>
            )}
          </div>
          <div className="bg-gray-50 p-4 rounded-md">
            <pre className="whitespace-pre-wrap font-mono text-sm overflow-y-auto h-36">
              {isLoading & !isPreferred ? <Loader className="w-4 h-4 animate-spin" /> : data.prompt}
            </pre>
          </div>
        </div>

        <div>
          <h3 className="text-lg font-semibold mb-2">Response</h3>
          <div className="bg-gray-50 p-4 rounded-md">
            <pre className="whitespace-pre-wrap font-mono text-sm overflow-y-auto h-20">
              {isLoading & (!isPreferred || !data.response) ? <Loader className="w-4 h-4 animate-spin" /> : data.response}
            </pre>
          </div>
        </div>

        <button
          onClick={() => handlePreferredSelection(pairNumber)}
          disabled={isLoading}
          className={`w-full py-2 px-4 rounded-md font-medium transition-colors ${isPreferred
              ? 'bg-yellow-500 text-white'
              : isLoading
                ? 'bg-gray-100 text-gray-400 cursor-not-allowed'
                : 'bg-blue-600 text-white hover:bg-blue-700'
            }`}
        >
          {isLoading ? (
            <span className="flex items-center justify-center gap-2">
              <Loader className="w-4 h-4 animate-spin" />
              Loading...
            </span>
          ) : isPreferred ? (
            'Select Current Champion'
          ) : (
            'Select New Champion'
          )}
        </button>
      </div>
    </div>
  );

  return (
    <div className="w-full max-w-6xl mx-auto text-left bg-white rounded-lg shadow-lg p-6">
      <Logo />
      <div className="progress-bar">
        <div className="progress-dot"></div>
        <div className="progress-line"></div>
        <div className="progress-dot"></div>
        <div className="progress-line"></div>
        <div className="progress-dot"></div>
      </div>
      <h2 className="text-2xl font-bold mb-8 text-center">Prompt Battle</h2>

      <div className="flex gap-6">
        <PairContainer
          pairNumber={1}
          data={pairs.pair1}
          isPreferred={preferredPair === 1}
        />
        <PairContainer
          pairNumber={2}
          data={pairs.pair2}
          isPreferred={preferredPair === 2}
        />
      </div>
      <div className="footer">
        <button onClick={() => { navigate(-1) }} className="next-button">
          ◀ Back
        </button>
        <button
          disabled={isLoading || !generatedPromptText}
          onClick={copyToClipboardAndGoToChatGPT} className="next-button">
          Try on ChatGPT!
        </button>
      </div>
    </div>
  );
};

export default CrayonPromptComparison;
