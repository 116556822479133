// OpenAIPromp.js
import React, { useEffect, useState } from 'react';
import { getObjectivesFromLLM } from './openaiApi';
import ObjectivesTable from './ObjectivesTable';

import { generateClient } from 'aws-amplify/data'

const client = generateClient({
  authMode: 'apiKey',
});

const createPromptLog = async (prompt, response) => {
  await client.models.Prompt.create({
    useragent: navigator.userAgent,
    ip_address: '',
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    prompt: prompt,
    response: response
  })
};

const OpenAIPrompt = (
  { prompt, generatedPrompt, setGeneratedPrompt, setParentLoading }) => {
  const [response, setResponse] = useState('');
  const [error, setError] = useState('');

  useEffect(() => {
    const callLLM = async () => {
      var result = '';
      try {
        // Call the function from openaiApi.js
        result = await getObjectivesFromLLM(prompt);
        setResponse(result);
      } catch (err) {
        setError(`An error occurred while calling OpenAI API: ${err}.`);
      } finally {
        await createPromptLog(prompt, JSON.stringify(JSON.parse(result).response));
      }
    }
    callLLM();
  }, [prompt]);

  useEffect(() => {
    if (response) setParentLoading(false);
  }, [response, setParentLoading]);

  return (
    <>
      {error && <p style={{ color: 'red' }}>{error}</p>}
      {response && (<ObjectivesTable jsonData={response} purpose={prompt} generatedPrompt={generatedPrompt} setGeneratedPrompt={setGeneratedPrompt} />)}
    </>
  );
};

export default OpenAIPrompt;
