// CrayonObjectives.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import OpenAIPrompt from './OpenAIPrompt';
import Spinner from './elements/Spinner';
import Logo from './elements/Logo';
import Feedback from './Feedback';

const promptFromGeneratedJSON = (promptJson) => {
  if (!promptJson) return;
  const objectives = promptJson.objectives.map(
      (str, index) => `${index + 1}. ${str}`).join('\n')
  const guidelines = promptJson.guidelines.map(
      (str, index) => `${index + 1}. ${str}`).join('\n')
  return `${promptJson.role}
${promptJson.instruction}
${objectives}
${promptJson.guardrail}
${guidelines}
`
}

const CrayonObjectives = () => {
  const [generatedPrompt, setGeneratedPrompt] = useState('');
  const [generatedPromptText, setGeneratedPromptText] = useState('');
  const [isLoading, setIsLoading] = useState(true);

  // From navigation
  const location = useLocation();
  const { inputText } = location.state || {};

  useEffect(() => {
    setGeneratedPromptText(promptFromGeneratedJSON(generatedPrompt));
  }, [generatedPrompt]);

  const navigate = useNavigate();
  /*
  const navigateToExperiment = () => {
    navigate('/experiment', { state: {inputText, generatedPromptText} });
  };
  */
  const navigateToEvaluation = () => {
    navigate('/evaluation', {
       state: {inputText, generatedPromptText, generatedPrompt }
    });
  }

  return (
    <div className='container'>
      <div style={{ flex: 3, paddingRight: '20px' }}>
        <Logo />
        <div className="progress-bar">
          <div className="progress-dot empty"></div>
          <div className="progress-line"></div>
          <div className="progress-dot"></div>
          <div className="progress-line"></div>
          <div className="progress-dot empty"></div>
        </div>

        <div className="container" style={{ padding: '20px', fontFamily: 'Arial, sans-serif' }}>
          <h2 className="text-2xl font-bold text-center mb-6">Purpose</h2>
          <p>{inputText}</p>
          {isLoading ? <Spinner /> : ''}
          {inputText &&
             <OpenAIPrompt
               prompt={inputText}
               generatedPrompt={generatedPrompt}
               setGeneratedPrompt={setGeneratedPrompt}
               setParentLoading={setIsLoading} />}
        </div>
        <div className="footer">
          <button onClick={() => {navigate(-1)}} className="next-button">
            ◀ Back
          </button>
          <Feedback />
          <button onClick={navigateToEvaluation} disabled={!generatedPromptText} className="next-button">
            Evaluate with Crayon
          </button>
        </div>
      </div>
    </div>
  );
};

export default CrayonObjectives;
