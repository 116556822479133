import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getStructuredPromptFromLLM, getOpenAIResponse, regeneratePromptFromFeedback, getSyntheticExamples } from './openaiApi';
import { Loader, Edit, Copy } from 'lucide-react';
import Logo from './elements/Logo.js';
import { generateClient } from 'aws-amplify/data'
const client = generateClient({
  authMode: 'apiKey',
});

// TODO: add logging for scoring table.
const createChatGPTLog = async (
  agentPrompt) => {
  await client.models.ChatGPT.create({
    useragent: navigator.userAgent,
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    agent_prompt: agentPrompt,
  })
};

const createPromptLog = async (prompt, response, openaiRequest, openaiResponse) => {
  await client.models.Prompt.create({
    useragent: navigator.userAgent,
    ip_address: '',
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    prompt: prompt,
    response: response,
    openai_request: JSON.stringify(openaiRequest),
    openai_response: JSON.stringify(openaiResponse)
  })
};

const CrayonPromptTester = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { combinedInput } = location.state || { combinedInput: '' };

  const [generatedPromptText, setGeneratedPromptText] = useState('');
  const [feedback, setFeedback] = useState('');
  const [inputText, setInputText] = useState('');
  const [outputText, setOutputText] = useState('');
  const [isLoading, setIsLoading] = useState(false);
  const [isOutputLoading, setIsOutputLoading] = useState(false);
  const [errorMessage, setErrorMessage] = useState('');
  const [isPopupVisible, setIsPopupVisible] = useState(false);
  const popupRef = useRef(null);
  const [feedbackHistory, setFeedbackHistory] = useState([]);
  const [isPromptPopupVisible, setIsPromptPopupVisible] = useState(false);
  const promptPopupRef = useRef(null);
  const [isPromptEditable, setIsPromptEditable] = useState(false);
  const textareaRef = useRef(null);
  const [showToast, setShowToast] = useState(false);
  const [placeholderText, setPlaceholderText] = useState('Enter input and press Enter...');
  const [isInputFlashing, setIsInputFlashing] = useState(false);

  const copyToClipboard = async (text) => {
    try {
      await navigator.clipboard.writeText(text);
      setShowToast(true);
      setTimeout(() => setShowToast(false), 2000); // Hide after 2 seconds
    } catch (err) {
      console.error('Failed to copy text: ', err);
    }
  };

  const promptFromJson = (json) => {
    return `${json.persona}

${json.goal ? `${json.goal}` : ''}

Think step by step when responding:
${json.cot.map((str, index) => `${index + 1}. ${str}`).join('\n')}

Your response must adhere to the following guardrails:
${json.guardrails.map((str, index) => `${index + 1}. ${str}`).join('\n')}
${json.examples ?
        '\nExamples: \n' + json.examples + '\n' : ''}
${json.format ? json.format + ':' : ''}`
  };

  useEffect(() => {
    const generatePrompt = async () => {
      setIsLoading(true);
      setErrorMessage('');
      try {
        // Call both API functions simultaneously
        const [promptResponse, exampleResponse] = await Promise.all([
          getStructuredPromptFromLLM(combinedInput),
          getSyntheticExamples(combinedInput, "")
        ]);

        // Process prompt response
        const result = JSON.parse(promptResponse.response).response;
        setGeneratedPromptText(promptFromJson(result));
        await createPromptLog(combinedInput, promptResponse.response, promptResponse.rawRequest, promptResponse.rawResponse);

        // Process examples response
        const examples = JSON.parse(exampleResponse.response).evaluation.test_cases;
        if (examples && examples.length > 0) {
          setPlaceholderText(examples[0].synthetic_example);
          setIsInputFlashing(true);
          setTimeout(() => setIsInputFlashing(false), 1000);
        }

        setIsLoading(false);
      } catch (error) {
        setErrorMessage('Failed to generate prompt. Please try again.');
      }
    };

    generatePrompt();
  }, [combinedInput]);

  const handleInputSubmit = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      
      // If input is empty, use placeholder text
      if (!inputText && placeholderText) {
        setInputText(placeholderText);
      }
      
      setIsOutputLoading(true);
      setErrorMessage('');
      try {
        const textToSubmit = inputText.trim() || placeholderText;
        const { response, rawRequest, rawResponse } = await getOpenAIResponse(generatedPromptText, textToSubmit);
        setOutputText(response);
        await createPromptLog(textToSubmit, response, rawRequest, rawResponse);
      } catch (error) {
        setErrorMessage('Failed to get response. Please try again.');
      } finally {
        setIsOutputLoading(false);
      }
    }
  };

  const handleFeedbackSubmit = async (e) => {
    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      setIsLoading(true);
      setInputText(''); // Clear the input
      setOutputText(''); // Clear the output
      setErrorMessage('');
      try {
        const { response, rawRequest, rawResponse } = await regeneratePromptFromFeedback(generatedPromptText, feedback);
        setGeneratedPromptText(response);
        setFeedbackHistory(prev => [...prev, {
          feedback,
          timestamp: new Date().toLocaleTimeString()
        }]);
        await createPromptLog(feedback, response, rawRequest, rawResponse);
        setFeedback(''); // Clear feedback after submission
      } catch (error) {
        setErrorMessage('Failed to regenerate prompt. Please try again.');
      } finally {
        setIsLoading(false);
      }
    }
  };

  const copyToClipboardAndGoToChatGPT = () => {
    // Copy the last message to the clipboard
    createChatGPTLog(generatedPromptText);
    navigator.clipboard.writeText(generatedPromptText);
    alert('Prompt copied to clipboard!\nAfter clicking OK, a ChatGPT will be opened in a new tab.\nPaste your new prompt to test!');
    const newTab = window.open('https://www.chatgpt.com', '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      alert("Failed to open tab...");
    }
  };

  const togglePopup = () => {
    setIsPopupVisible(!isPopupVisible);
  };

  const handleClickOutside = (event) => {
    if (popupRef.current && !popupRef.current.contains(event.target)) {
      setIsPopupVisible(false);
    }
    if (promptPopupRef.current && !promptPopupRef.current.contains(event.target)) {
      setIsPromptPopupVisible(false);
    }
  };

  const togglePromptPopup = () => {
    setIsPromptPopupVisible(!isPromptPopupVisible);
  };

  const togglePromptEdit = () => {
    setIsPromptEditable(!isPromptEditable);
  };

  useEffect(() => {
    const handleEscape = (event) => {
      if (event.key === 'Escape') {
        if (isPromptEditable) {
          setIsPromptEditable(false); // Save the prompt when editing
        } else {
          setIsPopupVisible(false);
          setIsPromptPopupVisible(false);
        }
      }
    };

    const handleClickOutside = (event) => {
      if (popupRef.current && !popupRef.current.contains(event.target)) {
        setIsPopupVisible(false);
      }
      if (promptPopupRef.current && !promptPopupRef.current.contains(event.target)) {
        setIsPromptPopupVisible(false);
      }
    };

    if (isPopupVisible || isPromptPopupVisible) {
      document.addEventListener('keydown', handleEscape);
      document.addEventListener('mousedown', handleClickOutside);
    } else {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
    }

    return () => {
      document.removeEventListener('keydown', handleEscape);
      document.removeEventListener('mousedown', handleClickOutside);
    };
  }, [isPopupVisible, isPromptPopupVisible]);

  useEffect(() => {
    if (isPromptEditable && textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [isPromptEditable]);

  // Add an instruction message
  const instructionMessage = "Press 'Escape' to save and close the editor.";

  return (
    <div className="w-full max-w-6xl mx-auto text-left bg-white rounded-lg shadow-lg p-6" style={{ height: 'calc(100vh - 40px)', margin: '20px 0' }}>
      <h1 className="text-2xl font-bold mb-4">Crayon Prompt Tester</h1>
      <div className="responsive-container flex flex-col md:flex-row gap-4 md:gap-24" style={{ height: 'calc(100vh - 240px)' }}>
        {/* Left Panel */}
        <div className="flex-1 p-4 rounded-lg border-2 border-gray-200 bg-white overflow-hidden flex flex-col">
          <div className="h-full space-y-4 overflow-y-auto">
            <div>
              <div className="flex justify-between items-center">
                <h3 className="text-lg font-semibold">Prompt</h3>
              </div>
              <div
                className="bg-gray-50 p-3 rounded-md flex-grow h-[calc(50vh-100px)] overflow-auto cursor-pointer"
                onClick={togglePromptPopup}
                style={{ height: 'calc(50vh - 120px)' }}
              >
                <pre className="whitespace-pre-wrap font-mono text-xs h-full">
                  {isLoading ? <Loader className="w-4 h-4 animate-spin" /> : generatedPromptText}
                </pre>
              </div>
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-2">How would you like the prompt to change?</h3>
              <p className="text-xs text-gray-500 mb-1">Press Enter to Update</p>
              <textarea
                placeholder='Enter feedback and press Enter...'
                value={feedback}
                onChange={(e) => setFeedback(e.target.value)}
                onKeyDown={handleFeedbackSubmit}
                className="w-full h-16 p-2.5 rounded border border-gray-300 resize-none"
              />
              <div className="mt-4 space-y-2">
                {feedbackHistory.map((item, index) => (
                  <div key={index}>
                    <span className="text-gray-400 text-xs">{item.timestamp}</span>
                    <p className="text-sm text-gray-600">{item.feedback}</p>
                    <hr className="my-2 border-gray-200" />
                  </div>
                ))}
              </div>
            </div>
          </div>
        </div>

        {/* Right Panel */}
        <div className="flex-1 p-4 rounded-lg border-2 border-gray-200 bg-white overflow-hidden flex flex-col">
          <div className="h-full space-y-4 overflow-y-auto">
            <div>
              <h3 className="text-lg font-semibold mb-1">Input</h3>
            <p className="text-xs text-gray-500 mb-1">Press Enter to use placeholder text</p>
              <textarea
                placeholder={`${placeholderText ? placeholderText : 'Enter input and press Enter...'}`}
                value={inputText}
                onChange={(e) => setInputText(e.target.value)}
                onKeyDown={handleInputSubmit}
                className={`w-full h-[60px] p-2 rounded border border-gray-300 resize-none ${isInputFlashing ? 'border-red-500' : ''}`}
              />
            </div>

            <div>
              <h3 className="text-lg font-semibold mb-1">Response</h3>
              <div
                className="bg-gray-50 p-3 rounded-md cursor-pointer h-[calc(50vh-100px)] overflow-auto"
                onClick={togglePopup}
              >
                <pre className={`whitespace-pre-wrap font-mono text-sm h-full ${!outputText && !isOutputLoading ? 'text-gray-400 italic' : ''}`}>
                  {isOutputLoading ? (
                    <Loader className="w-4 h-4 animate-spin" />
                  ) : (
                    outputText || 'No response yet. Please enter input and press Enter.'
                  )}
                </pre>
              </div>
            </div>
          </div>
        </div>
      </div>

      {/* Footer */}
      <div className="footer flex justify-between mt-4 flex-shrink-0">
        <button onClick={() => { navigate(-1) }} className="next-button">
          ◀ Back
        </button>
        <button
          disabled={isLoading || !generatedPromptText}
          onClick={copyToClipboardAndGoToChatGPT} 
          className="next-button">
          Try on ChatGPT!
        </button>
      </div>

      {/* Popup */}
      {isPopupVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div ref={popupRef} className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full max-h-[80vh] relative">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Response</h3>
              <div className="flex gap-2 items-center relative">
                <div className="relative">
                  <Copy
                    className="w-5 h-5 cursor-pointer text-gray-500 hover:text-gray-700"
                    onClick={() => copyToClipboard(outputText)}
                  />
                  {showToast && (
                    <div className="absolute right-0 top-[-30px] whitespace-nowrap bg-gray-800 text-white text-sm px-2 py-1 rounded shadow-lg">
                      Copied!
                    </div>
                  )}
                </div>
                <button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={togglePopup}
                >
                  ✖
                </button>
              </div>
            </div>
            <div className="overflow-y-auto max-h-[calc(80vh-100px)]">
              <pre className="whitespace-pre-wrap font-mono text-sm">
                {outputText}
              </pre>
            </div>
          </div>
        </div>
      )}

      {isPromptPopupVisible && (
        <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center">
          <div ref={promptPopupRef} className="bg-white p-6 rounded-lg shadow-lg max-w-3xl w-full max-h-[80vh] relative">
            <div className="flex justify-between items-center mb-4">
              <h3 className="text-lg font-semibold">Prompt</h3>
              <div className="flex gap-2 items-center relative">
                <div className="relative">
                  <Copy
                    className="w-5 h-5 cursor-pointer text-gray-500 hover:text-gray-700"
                    onClick={() => copyToClipboard(generatedPromptText)}
                  />
                  {showToast && (
                    <div className="absolute right-0 top-[-30px] whitespace-nowrap bg-gray-800 text-white text-sm px-2 py-1 rounded shadow-lg">
                      Copied!
                    </div>
                  )}
                </div>
                <Edit
                  className="w-5 h-5 cursor-pointer text-gray-500 hover:text-gray-700"
                  onClick={togglePromptEdit}
                />
                <button
                  className="text-gray-500 hover:text-gray-700"
                  onClick={togglePromptPopup}
                >
                  ✖
                </button>
              </div>
            </div>
            <div className="overflow-y-auto max-h-[calc(80vh-160px)]">
              {isPromptEditable ? (
                <>
                  <textarea
                    ref={textareaRef}
                    value={generatedPromptText}
                    onChange={(e) => setGeneratedPromptText(e.target.value)}
                    className="w-full min-h-[300px] p-2 rounded border border-gray-300 font-mono text-sm"
                    style={{ resize: 'vertical' }}
                  />
                  <p className="text-xs text-gray-500 mt-2">{instructionMessage}</p>
                </>
              ) : (
                <pre className="whitespace-pre-wrap font-mono text-sm">
                  {generatedPromptText}
                </pre>
              )}
            </div>
            <div className="mt-4 flex justify-end">
              <button
                disabled={isLoading || !generatedPromptText}
                onClick={copyToClipboardAndGoToChatGPT} 
                className="next-button">
                Try on ChatGPT!
              </button>
            </div>
          </div>
        </div>
      )}
    </div>
  );
};

// Add media queries for mobile responsiveness
const styles = `
  @media (max-width: 768px) {
    .responsive-container {
      flex-direction: column;
    }
    .next-button {
      width: 100%;
      margin-top: 10px;
    }
  }
`;

// Add the styles to the document
const styleSheet = document.createElement("style");
styleSheet.type = "text/css";
styleSheet.innerText = styles;
document.head.appendChild(styleSheet);

export default CrayonPromptTester; 