import React, { useState } from 'react';
import { generateClient } from 'aws-amplify/data'

const Feedback = () => {
  const [feedback, setFeedback] = useState(null); // 'up', 'down', or null
  const [explanation, setExplanation] = useState('');

  const client = generateClient({
    authMode: 'apiKey',
  });

  const handleThumbsUp = () => {
    setFeedback('up');
    setExplanation(''); // Clear explanation if feedback changes
  };

  const handleThumbsDown = () => {
    setFeedback('down');
    setExplanation(''); // Clear explanation if feedback changes
  };

  const handleExplanationChange = (e) => {
    setExplanation(e.target.value);
  };

  const createFeedbackLog = async (positive, feedback_explanation) => {
    await client.models.Feedback.create({
      useragent: navigator.userAgent,
      session_id: localStorage.getItem('sessionId'),
      timestamp: Date.now(),
      positive: positive,
      feedback: feedback_explanation
    })
  };

  const handleSubmit = () => {
    // Handle the feedback submission logic (e.g., send to API)
    createFeedbackLog(feedback === 'up', explanation)
    setFeedback(null); // Reset after submission
    setExplanation('');
  };

  return (
    <div style={{ textAlign: 'center', maxWidth: '300px', margin: '0 auto' }}>
      <h3>How was your experience?</h3>
      <div style={{ display: 'flex', alignItems: 'center', justifyContent: 'center', gap: '10px', fontSize: '24px' }}>
        <button
          onClick={handleThumbsUp}
          style={{
            color: feedback === 'up' ? 'green' : 'gray',
            cursor: 'pointer',
            border: 'none',
            background: 'transparent',
          }}
        >
          👍
        </button>
        <button
          onClick={handleThumbsDown}
          style={{
            color: feedback === 'down' ? 'red' : 'gray',
            cursor: 'pointer',
            border: 'none',
            background: 'transparent',
          }}
        >
          👎
        </button>
        {/* Show explanation text box only if thumbs down is selected */}
        {feedback === 'down' && (
          <textarea
            value={explanation}
            onChange={handleExplanationChange}
            placeholder="Tell us what went wrong..."
            style={{ width: '180px', height: '24px', padding: '4px', fontSize: '14px', resize: 'none' }}
          />
        )}
      </div>

      {/* Submit button */}
      <button
        onClick={handleSubmit}
        disabled={!feedback} // Disable if no feedback is selected
        style={{
          marginTop: '10px',
          padding: '8px 16px',
          fontSize: '16px',
          backgroundColor: feedback ? '#007BFF' : '#CCCCCC',
          color: 'white',
          border: 'none',
          borderRadius: '5px',
          cursor: feedback ? 'pointer' : 'not-allowed'
        }}
      >
        Submit Feedback
      </button>
    </div>
  );
};

export default Feedback;
