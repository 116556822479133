// CrayonPromptBattleMobile.js
import React, { useEffect, useState } from 'react';
import { Crown, Loader, ArrowLeftRight } from 'lucide-react';
import { getSimilarPrompt, getOpenAIResponse } from './openaiApi';
import Logo from './elements/Logo.js'

import { generateClient } from 'aws-amplify/data'
const client = generateClient({
  authMode: 'apiKey',
});

// TODO: add logging for scoring table.
const createChatGPTLog = async (
  agentPrompt) => {
  await client.models.ChatGPT.create({
    useragent: navigator.userAgent,
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    agent_prompt: agentPrompt,
  })
};

const createPromptLog = async (prompt, response, openaiRequest, openaiResponse) => {
  await client.models.Prompt.create({
    useragent: navigator.userAgent,
    ip_address: '',
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    prompt: prompt,
    response: response,
    openai_request: JSON.stringify(openaiRequest),
    openai_response: JSON.stringify(openaiResponse)
  })
};

const CrayonPromptBattleMobile = ({ inputText, generatedPromptText, inputTextResponse }) => {
  const [isLoading, setIsLoading] = useState(false);
  const [isViewingPreferred, setIsViewingPreferred] = useState(true);
  const [preferredPrompt, setPreferredPrompt] = useState({
    prompt: generatedPromptText,
    response: ""
  });
  const [candidatePrompt, setCandidatePrompt] = useState({
    prompt: inputText,
    response: inputTextResponse
  });

  // Simulate fetching a new candidate prompt
  const fetchNewCandidate = async () => {
    const { response: newPrompt, rawRequest: openaiRequest, rawResponse: openaiResponse } = await getSimilarPrompt(
      preferredPrompt.prompt, candidatePrompt.prompt);
    createPromptLog(preferredPrompt.prompt, candidatePrompt.prompt, openaiRequest, openaiResponse);
    return {
      prompt: newPrompt,
      response: (await getOpenAIResponse(newPrompt, "")).response
    };
  };

  const copyToClipboardAndGoToChatGPT = () => {
    if (preferredPrompt.prompt.length === 0) return;

    // Copy the last message to the clipboard
    createChatGPTLog(preferredPrompt.prompt);
    navigator.clipboard.writeText(preferredPrompt.prompt);
    alert('Prompt copied to clipboard!\nAfter clicking OK, a ChatGPT will be opened in a new tab.\nPaste your new prompt to test!');
    const newTab = window.open('https://www.chatgpt.com', '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      alert("Failed to open tab...");
    }
  };

  // Set generated response on initialization.
  useEffect(() => {
    setIsLoading(true);
    async function initializePrompt() {
      const generatedPromptResponse = (await getOpenAIResponse(generatedPromptText, "")).response;
      const newPair = {
        prompt: generatedPromptText,
        response: generatedPromptResponse
      }
      setPreferredPrompt(newPair);
      setIsLoading(false);
    }
    initializePrompt();
  }, [generatedPromptText]);


  const handlePreferredSelection = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      // Set current candidate as preferred
      setPreferredPrompt(candidatePrompt);
      // Get new candidate
      const newCandidate = await fetchNewCandidate();
      setCandidatePrompt(newCandidate);
      // Switch view to new candidate
      setIsViewingPreferred(false);
    } catch (error) {
      console.error('Error updating prompts:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const handleKeepPreferred = async () => {
    if (isLoading) return;
    setIsLoading(true);

    try {
      // Keep current preferred, just get new candidate
      const newCandidate = await fetchNewCandidate();
      setCandidatePrompt(newCandidate);
      // Switch view to new candidate
      setIsViewingPreferred(false);
    } catch (error) {
      console.error('Error updating candidate:', error);
    } finally {
      setIsLoading(false);
    }
  };

  const currentPrompt = isViewingPreferred ? preferredPrompt : candidatePrompt;

  return (
    <div className="w-full min-h-screen bg-gray-50">
      <div className="p-2 m-2">
        <Logo />
      </div>
      {/* Toggle Bar */}
      <div className="sticky top-0 z-10 bg-white border-b border-gray-200 shadow-sm">
        <div className="flex items-center justify-between px-4 py-2">
          <button
            onClick={() => setIsViewingPreferred(true)}
            className={`flex-1 py-2 px-3 text-sm font-medium rounded-l-lg transition-colors ${isViewingPreferred
                ? 'bg-yellow-400 text-yellow-900'
                : 'bg-gray-100 text-gray-600'
              }`}
          >
            <div className="flex items-center justify-center gap-1">
              {isViewingPreferred && <Crown className="w-4 h-4" />}
              Preferred
            </div>
          </button>
          <button
            onClick={() => setIsViewingPreferred(false)}
            className={`flex-1 py-2 px-3 text-sm font-medium rounded-r-lg transition-colors ${!isViewingPreferred
                ? 'bg-violet-600 text-white'
                : 'bg-gray-100 text-gray-600'
              }`}
          >
            Candidate
          </button>
        </div>
      </div>

      {/* Content Area */}
      <div className="p-4 space-y-4 animate-fadeIn">
        <div className="bg-white rounded-lg p-4 shadow-sm space-y-4">
          <div>
            <h3 className="text-lg font-semibold mb-2">Prompt</h3>
            <div className="bg-gray-50 p-3 rounded-md">
              <pre className="whitespace-pre-wrap font-mono text-sm overflow-y-auto max-h-24">
                {currentPrompt.prompt}
              </pre>
            </div>
          </div>

          <div>
            <h3 className="text-lg font-semibold mb-2">Response</h3>
            <div className="bg-gray-50 p-3 rounded-md">
              <pre className="whitespace-pre-wrap font-mono text-sm overflow-y-auto h-48">
                {currentPrompt.response}
              </pre>
            </div>
          </div>
        </div>

        {/* Action Buttons */}
        <div className="space-y-3">
          <button
            onClick={handleKeepPreferred}
            disabled={isLoading}
            className="w-1/2 py-3 px-4 bg-yellow-500 text-white rounded-lg font-medium
                disabled:bg-gray-100 disabled:text-gray-400 disabled:cursor-not-allowed
                transition-colors duration-200"
          >
            {isLoading ? (
              <span className="flex items-center justify-center gap-2">
                <Loader className="w-4 h-4 animate-spin" />
                Loading...
              </span>
            ) : (
              '◀ Like Preferred'
            )}
          </button>
          <button
            onClick={handlePreferredSelection}
            disabled={isLoading}
            className="w-1/2 py-3 px-4 bg-violet-600 text-white rounded-lg font-medium
                disabled:bg-gray-300 disabled:cursor-not-allowed
                transition-colors duration-200"
          >
            {isLoading ? (
              <span className="flex items-center justify-center gap-2">
                <Loader className="w-4 h-4 animate-spin" />
                Loading...
              </span>
            ) : (
              'Like Candidate ▶'
            )}
          </button>
        </div>
        <button
          disabled={isLoading || !generatedPromptText}
          onClick={copyToClipboardAndGoToChatGPT} className="bg-violet-600 font-medium rounded-lg text-white py-3 px-4">
          Try on ChatGPT!
        </button>
      </div>

      <style jsx global>{`
        @keyframes fadeIn {
          from { opacity: 0; }
          to { opacity: 1; }
        }
        .animate-fadeIn {
          animation: fadeIn 0.3s ease-out;
        }
      `}</style>
    </div>
  );
};

export default CrayonPromptBattleMobile;
