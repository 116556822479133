// CrayonEvaluation.js
import React, { useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Spinner from './elements/Spinner';
import Feedback from './Feedback';
import PromptScoringTable from './PromptScoringTable'
import { getRubricFromGoal, getSyntheticExamples, getSyntheticResponse, getEvaluationScore } from './openaiApi.js'

import { generateClient } from 'aws-amplify/data'

const client = generateClient({
  authMode: 'apiKey',
})

// TODO: add logging for scoring table.
const createChatGPTLog = async (
  agentPrompt) => {
  await client.models.ChatGPT.create({
    useragent: navigator.userAgent,
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    agent_prompt: agentPrompt,
  })
};

const parseExamples = (exampleJSON) => {
  let parsedExamples = []
  for (const example of JSON.parse(exampleJSON).evaluation.test_cases) {
    parsedExamples.push({ name: example.test_case, example: JSON.stringify(example.synthetic_example) });
  }
  return parsedExamples;
};

const CrayonEvaluation = () => {
  // const MAX_RUBRICS = 6;
  const [isLoading, setIsLoading] = useState(true);
  const [error, setError] = useState('');
  const [fullEvaluation, setFullEvaluation] = useState({});

  // From navigation
  const location = useLocation();
  const { inputText, generatedPromptText, generatedPrompt } = location.state || {};
  const navigate = useNavigate();
  const [rubric, setRubric] = useState({});


  // When generatedPrompt changes, generate a rubric.
  useEffect(() => {
    const callLLM = async () => {
      let rubrics = [];
      let examples = [];
      let evaluation = {
        'initial': {
          'results': []
        },
        'generated': {
          'results': []
        },
      };
      try {
        rubrics.push((await getRubricFromGoal(generatedPrompt.goal)).response);
        const parsedRubric = JSON.parse(rubrics[0]).rubric;
        setRubric(parsedRubric);
        examples = examples.concat(parseExamples((await getSyntheticExamples(generatedPrompt.goal, parsedRubric)).response));

        // Run examples against prompt
        let initialPromises = [];
        let generatedPromises = []
        for (const { example } of examples) {
          initialPromises.push(getSyntheticResponse(inputText, example));
          evaluation.initial.results.push({
            'input': example,
            'output': '',
            'score': -1,
            'justification': ''
          });
          generatedPromises.push(getSyntheticResponse(generatedPromptText, example));
          evaluation.generated.results.push({
            'input': example,
            'output': '',
            'score': -1,
            'justification': ''
          });
        }
        const initialResults = await Promise.all(initialPromises);
        const generatedResults = await Promise.all(generatedPromises);
        for (const i in initialResults) {
          evaluation.initial.results[i].output = initialResults[i].response;
          evaluation.generated.results[i].output = generatedResults[i].response;
        }
        let initialScorePromises = [];
        let generatedScorePromises = [];
        // Score examples
        for (const result of evaluation.generated.results) {
          generatedScorePromises.push(getEvaluationScore(result.input, result.output, generatedPrompt.goal, rubrics[0]))
        }
        for (const result of evaluation.initial.results) {
          initialScorePromises.push(getEvaluationScore(result.input, result.output, inputText, rubrics[0]))
        }
        const initialScores = await Promise.all(initialScorePromises);
        const generatedScores = await Promise.all(generatedScorePromises);
        for (const i in generatedScorePromises) {
          const generatedEval = JSON.parse(generatedScores[i].response);
          evaluation.generated.results[i].score = generatedEval.score;
          evaluation.generated.results[i].justification = generatedEval.justification;
          const initialEval = JSON.parse(initialScores[i].response);
          evaluation.initial.results[i].score = initialEval.score;
          evaluation.initial.results[i].justification = initialEval.justification;
        }
        setError('');
        setFullEvaluation(evaluation);
      } catch (err) {
        setError(`An error occurred while calling OpenAI API: ${err}.`);
      } finally {
        // await createPromptLog(prompt, JSON.stringify(JSON.parse(result).response));
        setIsLoading(false);
      }
    }
    callLLM();
  }, [generatedPrompt, generatedPromptText, inputText]);

  const copyToClipboardAndGoToChatGPT = () => {
    if (generatedPromptText.length === 0) return;

    // Copy the last message to the clipboard
    createChatGPTLog(generatedPromptText);
    navigator.clipboard.writeText(generatedPromptText);
    alert('Prompt copied to clipboard!\nAfter clicking OK, a ChatGPT will be opened in a new tab.\nPaste your new prompt to test!');
    const newTab = window.open('https://www.chatgpt.com', '_blank');
    if (newTab) {
      newTab.focus();
    } else {
      alert("Failed to open tab...");
    }
  };

  return (
    <div className='w-full max-w-6xl mx-auto bg-white rounded-lg shadow-lg p-6'>
      <div style={{ flex: 3, paddingRight: '20px' }}>
        <div className="logo-container">
          <svg className="logo" viewBox="0 0 100 100"></svg>
        </div>

        <div className="progress-bar">
          <div className="progress-dot"></div>
          <div className="progress-line"></div>
          <div className="progress-dot"></div>
          <div className="progress-line"></div>
          <div className="progress-dot"></div>
        </div>

        {isLoading ? <Spinner /> : ''}
        {error ? error : ''}
        <h2 className="text-2xl font-bold text-center mb-6">Prompt Evaluation</h2>
        
        {!isLoading && (
          <div className="mb-8">
            <table className="w-full border-collapse border border-gray-300">
              <thead>
                <tr>
                  <th className="w-1/2 border border-gray-300 p-3 bg-gray-50">Generated Prompt</th>
                  <th className="w-1/2 border border-gray-300 p-3 bg-gray-50">Initial Prompt</th>
                </tr>
              </thead>
              <tbody>
                <tr>
                 <td className="border border-gray-300 p-4 align-top">
                    <div className="max-h-48 overflow-y-auto">{generatedPromptText}</div>
                  </td>
                  <td className="border border-gray-300 p-4 align-top">
                    <div className="max-h-48 overflow-y-auto">{inputText}</div>
                  </td>
                </tr>
              </tbody>
            </table>
          </div>
        )}

        {isLoading ? '' : <PromptScoringTable
          initialPrompt={inputText}
          generatedPrompt={generatedPromptText}
          fullEvaluation={fullEvaluation}
          rubric={rubric}
        />
        }
        <div className="footer">
          <button onClick={() => { navigate(-1) }} className="next-button">
            ◀ Back
          </button>
          <Feedback />
          <button onClick={copyToClipboardAndGoToChatGPT} disabled={!generatedPromptText} className="next-button">
            Try on ChatGPT!
          </button>
        </div>
      </div>
    </div>
  );
};

export default CrayonEvaluation;
