import React, { useEffect, useState } from 'react';
import { ChevronDown, ChevronRight } from 'lucide-react';
import Rubric from './Rubric';

const PromptScoringTable = ({ initialPrompt, generatedPrompt, fullEvaluation, rubric }) => {
  const [expandedRows, setExpandedRows] = useState(new Set());
  const [expandedScore, setExpandedScore] = useState(null);
  const [promptData, setPromptData] = useState([]);

  const averageEvalScores = array => array.reduce((a, b) => a + b.score, 0) / array.length * 2;

  useEffect(() => {
    if (!fullEvaluation.generated) return;
    
    // Restructure data to align generated and initial results by input
    const combinedData = fullEvaluation.generated.results.map((genResult, index) => ({
      input: genResult.input,
      generated: {
        score: genResult.score,
        output: genResult.output,
        justification: genResult.justification
      },
      initial: {
        score: fullEvaluation.initial.results[index].score,
        output: fullEvaluation.initial.results[index].output,
        justification: fullEvaluation.initial.results[index].justification
      }
    }));

    setPromptData(combinedData);
  }, [fullEvaluation]);

  const toggleRow = (index) => {
    // Close score expansion if open
    setExpandedScore(null);
    
    // Toggle row expansion
    const newExpanded = new Set(expandedRows);
    if (newExpanded.has(index)) {
      newExpanded.delete(index);
    } else {
      newExpanded.add(index);
    }
    setExpandedRows(newExpanded);
  };

  const toggleScore = (index) => {
    // Close row expansions
    setExpandedRows(new Set());
    
    // Toggle score expansion
    setExpandedScore(expandedScore === index ? null : index);
  };

  const getAverageScores = () => ({
    generated: averageEvalScores(fullEvaluation.generated.results),
    initial: averageEvalScores(fullEvaluation.initial.results)
  });

  return (
    <div>
      <table className="w-full border-collapse">
        <thead>
          <tr className="bg-gray-100">
            <th className="border border-gray-200 p-4 text-left">Input</th>
            <th className="border border-gray-200 p-4 text-center">Generated Score</th>
            <th className="border border-gray-200 p-4 text-center">Initial Score</th>
          </tr>
        </thead>
        <tbody>
          {promptData.map((row, index) => (
            <React.Fragment key={index}>
              <tr className="bg-white">
                <td
                  className="border border-gray-200 p-4 cursor-pointer hover:bg-gray-50 transition-colors"
                  onClick={() => toggleRow(index)}
                >
                  <div className="flex items-center gap-2">
                    {expandedRows.has(index) ? (
                      <ChevronDown className="w-4 h-4" />
                    ) : (
                      <ChevronRight className="w-4 h-4" />
                    )}
                    <div className="h-24 overflow-y-auto text-sm">{row.input}</div>
                  </div>
                </td>
                <td 
                  className="border border-gray-200 p-4 text-center cursor-pointer hover:bg-gray-50 transition-colors"
                  onClick={() => toggleScore(index)}
                >
                  <span className={`inline-block rounded px-2 py-1 ${
                    row.generated.score * 2 >= 9 ? 'bg-green-100 text-green-800' :
                    row.generated.score * 2 >= 7 ? 'bg-blue-100 text-blue-800' :
                    'bg-yellow-100 text-yellow-800'
                  }`}>
                    {(row.generated.score * 2).toFixed(1)}
                  </span>
                </td>
                <td 
                  className="border border-gray-200 p-4 text-center cursor-pointer hover:bg-gray-50 transition-colors"
                  onClick={() => toggleScore(index)}
                >
                  <span className={`inline-block rounded px-2 py-1 ${
                    row.initial.score * 2 >= 9 ? 'bg-green-100 text-green-800' :
                    row.initial.score * 2 >= 7 ? 'bg-blue-100 text-blue-800' :
                    'bg-yellow-100 text-yellow-800'
                  }`}>
                    {(row.initial.score * 2).toFixed(1)}
                  </span>
                </td>
              </tr>
              {expandedRows.has(index) && (
                <tr className="bg-gray-50">
                  <td colSpan="3" className="border border-gray-200 p-4">
                    <div className="grid grid-cols-2 gap-4">
                      <div>
                        <h4 className="font-semibold mb-2">Generated Response</h4>
                        <div className="space-y-4">
                          <div>
                            <div className="font-medium text-sm text-gray-600">Output:</div>
                            <div className="h-24 overflow-y-auto text-sm border p-2 bg-white">{row.generated.output}</div>
                          </div>
                          <div>
                            <div className="font-medium text-sm text-gray-600">Justification:</div>
                            <div className="h-24 overflow-y-auto text-sm border p-2 bg-white">{row.generated.justification}</div>
                          </div>
                        </div>
                      </div>
                      <div>
                        <h4 className="font-semibold mb-2">Initial Response</h4>
                        <div className="space-y-4">
                          <div>
                            <div className="font-medium text-sm text-gray-600">Output:</div>
                            <div className="h-24 overflow-y-auto text-sm border p-2 bg-white">{row.initial.output}</div>
                          </div>
                          <div>
                            <div className="font-medium text-sm text-gray-600">Justification:</div>
                            <div className="h-24 overflow-y-auto text-sm border p-2 bg-white">{row.initial.justification}</div>
                          </div>
                        </div>
                      </div>
                    </div>
                  </td>
                </tr>
              )}
              {expandedScore === index && (
                <tr className="bg-gray-50">
                  <td colSpan="3" className="border border-gray-200 p-4">
                    <div className="space-y-4">
                      <h4 className="font-semibold mb-2">Evaluation Rubric</h4>
                      <div className="text-sm whitespace-pre-wrap bg-white border p-4 rounded">
                        <Rubric rubricData={rubric} />
                      </div>
                    </div>
                  </td>
                </tr>
              )}
            </React.Fragment>
          ))}
          {/* Average Scores Row */}
          <tr className="bg-gray-100 font-semibold">
            <td className="border border-gray-200 p-4">Overall Average</td>
            <td className="border border-gray-200 p-4 text-center">
              {getAverageScores().generated.toFixed(1)}
            </td>
            <td className="border border-gray-200 p-4 text-center">
              {getAverageScores().initial.toFixed(1)}
            </td>
          </tr>
        </tbody>
      </table>
    </div>
  );
};
export default PromptScoringTable;
