// App.js
import { Amplify } from 'aws-amplify';
import React from 'react';
import {
  BrowserRouter, Routes, Route,
} from 'react-router-dom';
import './App.css';
import CrayonEvaluation from './CrayonEvaluation';
import CrayonExperiment from './CrayonExperiment';
import CrayonInput from './CrayonInput';
import CrayonPrompt from './CrayonPrompt';
import CrayonBattle from './CrayonBattle';
import CrayonPromptBattleMobile from './CrayonPromptBattleMobile';
import CrayonObjectives from './CrayonObjectives';
import outputs from './amplify_outputs.json';
import CrayonQuestions from './CrayonQuestions';
import CrayonPromptTester from './CrayonPromptTester';

import { useEffect, useState } from 'react';

Amplify.configure(outputs);


const useSessionId = () => {
  const [sessionId, setSessionId] = useState(null);

  useEffect(() => {
    // Check if session ID already exists in localStorage
    let storedSessionId = localStorage.getItem('sessionId');

    if (!storedSessionId) {
      // Generate a new session ID if none exists
      storedSessionId = generateSessionId();
      localStorage.setItem('sessionId', storedSessionId);
    }

    setSessionId(storedSessionId);
  }, []);

  return sessionId;
};

const generateSessionId = () => {
  return Math.random().toString(36).substring(2, 15) + Math.random().toString(36).substring(2, 15);
};

function App() {
  const sessionId = useSessionId();
  useEffect(() => {
    console.log("Session ID:", sessionId);
  }, [sessionId]);

  return (
    <div className="App">
      <BrowserRouter>
        <Routes>
           <Route exact path = "/experiment" element = {<CrayonExperiment />}/>
           <Route exact path = "/evaluation" element = {<CrayonEvaluation />}/>
           <Route exact path = "/objectives" element = {<CrayonObjectives />}/>
           <Route exact path = "/battlemobile" element = {<CrayonPromptBattleMobile />}/>
           <Route exact path = "/battle" element = {<CrayonBattle />}/>
           <Route exact path = "/prompt" element = {<CrayonPrompt />}/>
           <Route exact path = "/" element = {<CrayonInput />}/>
           <Route path="/questions" element={<CrayonQuestions />} />
           <Route path="/prompt-tester" element={<CrayonPromptTester />} />
        </Routes>
      </BrowserRouter>
    </div>
  );
}

export default App;
