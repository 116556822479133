// Spinner.js
import './Spinner.css'; // Import CSS for spinner styling

const Spinner = () => (
  <div data-testid="spinner">
    <div className="spinner"></div>
  </div>
);

export default Spinner;
