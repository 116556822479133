// CrayonBattle.js
import { useEffect, useState } from 'react';
import { useLocation } from 'react-router-dom';
import { isMobile } from 'react-device-detect';
import CrayonPromptComparison from './CrayonPromptComparison'
import CrayonPromptBattleMobile from './CrayonPromptBattleMobile'

// Choose between mobile and desktop experiences.
const CrayonBattle = () => {
  // From navigation
  const location = useLocation();
  const { inputText, generatedPromptText, inputTextResponse } = location.state || {};
  const [useMobile, setUseMobile] = useState(false);

  useEffect(() => {
    setUseMobile(isMobile);
  }, [isMobile]);

  return (
    <div>
    {useMobile ?
      <CrayonPromptBattleMobile
       inputText={inputText}
       generatedPromptText={generatedPromptText}
       inputTextResponse={inputTextResponse}
       />
    :
      <CrayonPromptComparison
       inputText={inputText}
       generatedPromptText={generatedPromptText}
       inputTextResponse={inputTextResponse}
       />
    }
    </div>
  );
};

export default CrayonBattle;
