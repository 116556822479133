// CrayonPrompt.js
import { useCallback, useEffect, useState } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { generateClient } from 'aws-amplify/data'
import { getOpenAIResponse, getStructuredPromptFromLLM } from './openaiApi';
import Spinner from './elements/Spinner.js';
import Logo from './elements/Logo.js';

const client = generateClient({
  authMode: 'apiKey',
});

const createPromptLog = async (prompt, response, openaiRequest, openaiResponse) => {
  await client.models.Prompt.create({
    useragent: navigator.userAgent,
    ip_address: '',
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    prompt: prompt,
    response: response,
    openai_request: JSON.stringify(openaiRequest),
    openai_response: JSON.stringify(openaiResponse)
  })
};

const CrayonPrompt = () => {
  const [generatedPrompt, setGeneratedPrompt] = useState({});
  const [error, setError] = useState('');
  const [isLoading, setIsLoading] = useState(true);
  const [persona, setPersona] = useState('');
  const [goal, setGoal] = useState('');
  const [cot, setCot] = useState([]);
  const [guardrails, setGuardrails] = useState([]);
  const [generatedPromptText, setGeneratedPromptText] = useState('');
  const [inputTextResponse, setInputTextResponse] = useState('');
  const [shouldNavigateToEvaluation, setShouldNavigateToEvaluation] = useState(false);

  // From navigation
  const location = useLocation();
  const { inputText } = location.state || {};

  const navigate = useNavigate();

  const handleKeyDown = (e) => {
    if (isLoading) return;
    if (e.key === 'Shift') {
      setShouldNavigateToEvaluation(true);
    }
  };

  const handleKeyUp = (e) => {
    if (e.key === 'Shift') {
      setShouldNavigateToEvaluation(false);
    }
  };

  const [formData, setFormData] = useState({
    persona: '',
    goal: '',
    cot: '',
    guardrails: '',
  });

  const promptFromJson = (json) => {
    return `${json.persona}

${json.goal ? `${json.goal}` : ''}

Think step by step when responding:
${json.cot}

Your response must adhere to the following guardrails:
${json.guardrails}
${json.examples ?
        '\nExamples: \n' + json.examples + '\n' : ''}
${json.format ? json.format + ':' : ''}`
  };

  const promptFromPromptText = () => {
    const editedPrompt =
    {
      persona: formData.persona,
      goal: formData.goal,
      cot: formData.cot.split('\n'),
      guardrails: formData.guardrails.split('\n')
    };
    return editedPrompt;
  };

  const promptFromFormData = useCallback(() => {
    return promptFromJson(formData)
  }, [formData]);

  const navigateToBattle = useCallback(() => {
    const newPromptText = promptFromFormData();
    const newPrompt = promptFromPromptText();

    setGeneratedPromptText(newPromptText);
    setGeneratedPrompt(newPrompt);

    if (shouldNavigateToEvaluation) {
      navigate('/evaluation', {
        state: { inputText, generatedPromptText: newPromptText, generatedPrompt: newPrompt }
      });
      return;
    }

    navigate('/battle', {
      state: { inputText, generatedPromptText: newPromptText, inputTextResponse }
    });
  }, [promptFromFormData, promptFromPromptText, shouldNavigateToEvaluation, inputText, inputTextResponse, navigate]);

  useEffect(() => {
    document.addEventListener('keydown', handleKeyDown);
    document.addEventListener('keyup', handleKeyUp);
  });

  useEffect(() => {
    setPersona(generatedPrompt.persona);
    setGoal(generatedPrompt.goal);
    setCot(generatedPrompt.cot);
    setGuardrails(generatedPrompt.guardrails);
  }, [generatedPrompt]);

  useEffect(() => {
    updateFormData('persona', persona);
  }, [persona]);

  useEffect(() => {
    updateFormData('goal', goal);
  }, [goal]);

  useEffect(() => {
    if (cot) updateFormData(
      'cot',
      cot.map((str, index) => `${index + 1}. ${str}`).join('\n'));
  }, [cot]);

  useEffect(() => {
    if (guardrails) updateFormData('guardrails', guardrails.map(
      (str, index) => `${index + 1}. ${str}`).join('\n'));
  }, [guardrails]);

  useEffect(() => {
    const callLLM = async () => {
      setIsLoading(true);
      var result = '';
      let openaiRequest = {};
      let openaiResponse = {};
      try {
        // Call the function from openaiApi.js
        const { response, rawRequest, rawResponse } = await getStructuredPromptFromLLM(inputText);
        result = JSON.parse(response).response;
        setGeneratedPrompt(result);
        openaiRequest = rawRequest;
        openaiResponse = rawResponse;
        // Temporarily to allow the form time to populate.
        setGeneratedPromptText(promptFromJson(result));
        setInputTextResponse((await getOpenAIResponse(inputText, "")).response);
      } catch (err) {
        setError(`An error occurred while calling OpenAI API: ${err}.`);
        result = `${err}`
      } finally {
        setIsLoading(false);
        await createPromptLog(inputText, result, openaiRequest, openaiResponse);
      }
    }
    callLLM();
  }, [inputText])

  useEffect(() => {
    setGeneratedPromptText(promptFromFormData());
  }, [formData, promptFromFormData])

  useEffect(() => {
    setGeneratedPromptText(promptFromFormData());
  }, [formData, promptFromFormData])

  const updateFormData = (name, value) => {
    setFormData(prevState => ({
      ...prevState,
      [name]: value
    }));
  };

  const handleChange = (e) => {
    const { name, value } = e.target;
    updateFormData(name, value);
  };

  return (
    <div className="w-full max-w-3xl mx-auto text-left bg-white rounded-lg shadow-lg p-6"
      data-testid="page">
      <Logo />
      <div className="progress-bar">
        <div className="progress-dot"></div>
        <div className="progress-line"></div>
        <div className="progress-dot"></div>
        <div className="progress-line"></div>
        <div className="progress-dot empty"></div>
      </div>
      <h3 className="text-2xl font-bold text-center p-0">Refine your prompt!</h3>
      <p className="p-2">Edit the first version of your prompt based on our best guess of your intent.</p>
      {isLoading ? <Spinner /> : ''}
      <p className="text-red-500">{error ? error : ''}</p>
      <form className="space-y-4">
        {/* Persona Input */}
        <div className="space-y-2">
          <label
            htmlFor="persona"
            className="block text-sm font-medium text-gray-700"
          >
            Persona
          </label>
          <textarea
            id="persona"
            name="persona"
            value={formData.persona}
            onChange={handleChange}
            rows={3}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="Enter the persona description..."
          />
        </div>

        {/* Goal Input */}
        <div className="space-y-2">
          <label
            htmlFor="goal"
            className="block text-sm font-medium text-gray-700"
          >
            Goal
          </label>
          <textarea
            id="goal"
            name="goal"
            value={formData.goal}
            onChange={handleChange}
            rows={3}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="Enter the goal description..."
          />
        </div>

        {/* Chain-of-thought Input */}
        <div className="space-y-2">
          <label
            htmlFor="cot"
            className="block text-sm font-medium text-gray-700"
          >
            Chain-of-thought
          </label>
          <textarea
            id="cot"
            name="cot"
            value={formData.cot}
            onChange={handleChange}
            rows={4}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="Enter the chain-of-thought..."
          />
        </div>

        {/* Guardrails Input */}
        <div className="space-y-2">
          <label
            htmlFor="guardrails"
            className="block text-sm font-medium text-gray-700"
          >
            Guardrails
          </label>
          <textarea
            id="guardrails"
            name="guardrails"
            value={formData.guardrails}
            onChange={handleChange}
            rows={4}
            className="w-full px-3 py-2 border border-gray-300 rounded-md shadow-sm focus:border-blue-500 focus:ring-1 focus:ring-blue-500"
            placeholder="Enter the guardrails..."
          />
        </div>
      </form>
      <div className="footer">
        <button onClick={() => { navigate(-1) }} className="next-button">
          ◀ Back
        </button>
        <button
          disabled={isLoading || !generatedPromptText}
          onClick={navigateToBattle} className="next-button">
          Next ▶
        </button>
      </div>
    </div>
  );
};

export default CrayonPrompt;
