// CrayonInput.js
import React, { useState, useRef, useEffect } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import { getOpenAIResponse } from './openaiApi';
import Spinner from './elements/Spinner';
import Feedback from './Feedback';

import { generateClient } from 'aws-amplify/data'

const client = generateClient({
  authMode: 'apiKey',
})

const createChatLog = async (
    agentPrompt,
    model,
    temperature,
    message,
    response) => {
  await client.models.Chat.create({
    useragent: navigator.userAgent,
    session_id: localStorage.getItem('sessionId'),
    timestamp: Date.now(),
    agent_prompt: agentPrompt,
    model: model,
    temperature: temperature,
    message: message,
    response: response
  })
};

const CrayonExperiment = () => {
  // From navigation
  const location = useLocation();
  const { inputText, generatedPromptText } = location.state || {};

  const [model, setModel] = useState("gpt-3.5-turbo");
  const [temperature, setTemperature] = useState(0); // Default to 'Consistent'
  const [initialPrompt, setInitialPrompt] = useState(inputText);
  const [agentPrompt, setAgentPrompt] = useState(generatedPromptText);
  const [showGeneratedPrompt, setShowGeneratedPrompt] = useState(true);
  const [chatLogs, setChatLogs] = useState([]);
  const [userMessage, setUserMessage] = useState("");
  const [isLoading, setIsLoading] = useState(false);
  const [isFocused, setIsFocused] = useState(false); // New state to manage focus

  // Ref to the chat log container
  const chatLogRef = useRef(null);

  const navigate = useNavigate();
  const textAreaRef = useRef(null);

  useEffect(() => {
    if (chatLogRef.current) {
      chatLogRef.current.scrollTop = chatLogRef.current.scrollHeight;
    }
  }, [chatLogs]);

  // Handle prompt toggle switch
  const handleTogglePrompt = () => {
    setShowGeneratedPrompt(!showGeneratedPrompt);
  };

  // Handle submit button click
  const handleSubmit = async () => {
    if (!userMessage.trim()) return;

    const newChatLog = { role: "user", content: userMessage };
    setChatLogs([...chatLogs, newChatLog]);
    setUserMessage("");
    setIsLoading(true);

    var response = ''
    try {
      response = await getOpenAIResponse(
          showGeneratedPrompt ? agentPrompt : initialPrompt,  // system prompt
          userMessage,  // user prompt
          false,  // format_json
          temperature,  // temperature
          model  // model
      );
      const chatGPTMessage = { role: "assistant", content: response };
      setChatLogs((prevLogs) => [...prevLogs, chatGPTMessage]);
    } catch (error) {
      console.error("Error calling OpenAI API:", error);
    } finally {
      setIsLoading(false);
      createChatLog(
        showGeneratedPrompt ? agentPrompt : initialPrompt,
        model, temperature, userMessage, response);
    }
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter') {
      e.preventDefault(); // Prevent default form submission or new line in text area
      handleSubmit(); // Trigger the send action
    }
  };

  return (
    <div className='container'>
      <div style={{ flex: 3, paddingRight: '20px' }}>
        <div className="logo-container">
          <svg className="logo" viewBox="0 0 100 100"></svg>
        </div>

        <div className="progress-bar">
          <div className="progress-dot empty"></div>
          <div className="progress-line"></div>
          <div className="progress-dot empty"></div>
          <div className="progress-line"></div>
          <div className="progress-dot"></div>
        </div>
      {/* Toolbar with model and temperature selector */}
      <h2> Try out your new AI Agent!</h2>
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <label style={{ marginRight: '10px' }}>Select Model:</label>
        <select value={model} onChange={(e) => setModel(e.target.value)} style={{ padding: '5px', fontSize: '16px' }}>
          <option value="gpt-3.5-turbo">GPT-3.5 Turbo</option>
          <option value="gpt-4">GPT-4</option>
          <option value="gpt-4-turbo">GPT-4 Turbo</option>
          {/* Add more model versions as needed */}
        </select>
        <label style={{ marginLeft: '10px', marginRight: '10px' }}>Temperature:</label>
        <select value={temperature} onChange={(e) => setTemperature(Number(e.target.value))} style={{ padding: '5px', fontSize: '16px' }}>
          <option value={0.0}>Consistent</option>
          <option value={0.5}>Goldilocks</option>
          <option value={1.0}>Wild</option>
        </select>
      </div>

      {/* Toggling text area */}
      <div style={{ display: 'flex', alignItems: 'center', marginBottom: '10px' }}>
        <textarea
          ref={textAreaRef}
          rows="3"
          style={{
            width: '100%',
            height: '7vh',
            resize: 'vertical',
            fontSize: '16px',
            padding: '10px',
            backgroundColor: isFocused ? 'white' : '#f0f0f0',
          }}
          value={showGeneratedPrompt ? agentPrompt : initialPrompt}
          onChange={(e) => {
            showGeneratedPrompt ? setAgentPrompt(e.target.value) : setInitialPrompt(e.target.value);
          }}
          onFocus={() => setIsFocused(true)} // Set focus state
          onBlur={() => setIsFocused(false)} // Reset focus state
        />
        <label style={{ marginLeft: '10px' }}>
          <input type="checkbox" checked={showGeneratedPrompt} onChange={handleTogglePrompt} />
          Use Crayon Prompt
        </label>
      </div>

      {/* Chat log (non-editable) */}
      <div ref={chatLogRef} style={{ textAlign: 'left', flex: 1, overflowY: 'auto', backgroundColor: '#f9f9f9', padding: '10px', marginBottom: '10px', borderRadius: '5px', border: '1px solid #ddd',
              maxHeight: '30vh'
}}>
        {chatLogs.map((log, index) => (
          <div
            key={index}
            style={{
              marginBottom: '5px',
            }}>
            <strong style={{color: log.role === "user" ? "blue" : "red"}}>{log.role === "user" ? "You:" : "ChatGPT:"}</strong> {log.content}
          </div>
        ))}
      {isLoading ? <Spinner /> : ''}
      </div>

      {/* Chat input and submit button */}
      <div style={{ display: 'flex', alignItems: 'center' }}>
        <input
          type="text"
          placeholder="Type your message..."
          value={userMessage}
          onChange={(e) => setUserMessage(e.target.value)}
          onKeyDown={handleKeyDown} // Attach the onKeyDown event
          style={{ flex: 1, padding: '10px', fontSize: '16px', marginRight: '10px', borderRadius: '5px', border: '1px solid #ddd' }}
        />
        <button onClick={handleSubmit} disabled={isLoading} style={{ padding: '10px 20px', fontSize: '16px', borderRadius: '5px', backgroundColor: '#007BFF', color: 'white', cursor: isLoading ? 'not-allowed' : 'pointer', border: 'none' }}>
          {isLoading ? 'Loading...' : 'Send'}
        </button>
      </div>

    </div>
    <div className="footer">
      <button onClick={() => {navigate(-1)}} className="next-button">
        ◀ Back
      </button>
      <Feedback />
    </div>
  </div>
  );
};

export default CrayonExperiment;
