import React, { useState, useEffect, useRef } from 'react';
import { useLocation, useNavigate } from 'react-router-dom';
import Logo from './elements/Logo.js';
import { motion, AnimatePresence } from 'framer-motion';
import Feedback from './Feedback';

const CrayonQuestions = () => {
  const location = useLocation();
  const navigate = useNavigate();
  const { questions, originalInput } = location.state || { questions: [], originalInput: '' };
  
  const [currentQuestionIndex, setCurrentQuestionIndex] = useState(0);
  const [answers, setAnswers] = useState({});
  const [isTyping, setIsTyping] = useState(true);
  const textareaRef = useRef(null);

  useEffect(() => {
    if (textareaRef.current) {
      textareaRef.current.focus();
    }
  }, [currentQuestionIndex, isTyping]);

  useEffect(() => {
    const timer = setTimeout(() => {
      setIsTyping(false);
    }, 1000);
    return () => clearTimeout(timer);
  }, [currentQuestionIndex]);

  const handleAnswerChange = (value) => {
    setAnswers(prev => ({
      ...prev,
      [currentQuestionIndex]: value
    }));
  };

  const handleKeyDown = (e) => {
    if (e.key === 'Enter' && (e.metaKey || e.ctrlKey)) {
      e.preventDefault();
      navigateToPromptTester();
      return;
    }

    if (e.key === 'Enter' && !e.shiftKey) {
      e.preventDefault();
      if (currentQuestionIndex === questions.length - 1) {
        navigateToPromptTester();
      } else {
        setCurrentQuestionIndex(prev => prev + 1);
        setIsTyping(true);
      }
    }
  };

  const navigateToPromptTester = () => {
    const combinedInput = `
Original Request: ${originalInput}

Additional Context:
${questions
  .map((q, i) => ({ question: q.question, answer: answers[i] }))
  .filter(({ answer }) => answer && answer.trim() !== '')
  .map(({ question, answer }) => `Q: ${question}\nA: ${answer}`)
  .join('\n\n')}
    `;

    navigate('/prompt-tester', { 
      state: { 
        inputText: originalInput,
        combinedInput: combinedInput
      } 
    });
  };

  const progressPercentage = ((currentQuestionIndex + 1) / questions.length) * 100;

  return (
    <div className='container'>
      <div style={{ flex: 3, paddingRight: '20px', position: 'relative' }}>
        <Logo />
        <div className="progress-bar">
          <div className="progress-dot"></div>
          <div className="progress-line"></div>
          <div className="progress-dot"></div>
          <div className="progress-line"></div>
          <div className="progress-dot empty"></div>
        </div>
        <div style={{ minHeight: '300px' }}>
          <AnimatePresence mode='wait'>
            <motion.div
              key={currentQuestionIndex}
              initial={{ opacity: 0, y: 5 }}
              animate={{ opacity: 1, y: 0 }}
              exit={{ opacity: 0, y: -5 }}
              transition={{ duration: 0.3 }}
            >
              <h3 style={{ 
                marginBottom: '20px',
                opacity: isTyping ? 0.7 : 1,
                transition: 'opacity 0.3s'
              }}>
                {questions[currentQuestionIndex]?.question}
              </h3>
              
              <textarea
                ref={textareaRef}
                className='text-area'
                placeholder='Type your answer and press Enter...'
                style={{
                  width: '100%',
                  minHeight: '120px',
                  fontSize: '1.1rem',
                  padding: '15px',
                  boxSizing: 'border-box',
                  border: '2px solid #eee',
                  borderRadius: '8px',
                  resize: 'none',
                  transition: 'border-color 0.3s, box-shadow 0.3s',
                  outline: 'none',
                }}
                value={answers[currentQuestionIndex] || ''}
                onChange={(e) => handleAnswerChange(e.target.value)}
                onKeyDown={handleKeyDown}
              />

              <div style={{
                marginTop: '10px',
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                color: '#666',
                fontSize: '0.9rem'
              }}>
                <span>Question {currentQuestionIndex + 1} of {questions.length}</span>
                <span>
                  Press Enter to continue
                </span>
              </div>
            </motion.div>
          </AnimatePresence>
          <div style={{
            width: '100%',
            height: '4px',
            background: '#eee',
            marginBottom: '40px',
            marginTop: '10px',
            borderRadius: '2px',
          }}>
            <motion.div
              style={{
                height: '100%',
                background: '#6A0DAD',
                borderRadius: '2px',
              }}
              animate={{ width: `${progressPercentage}%` }}
              transition={{ duration: 0.6 }}
            />
        </div>


        </div>

        <div className="footer" style={{ marginTop: '40px', display: 'flex', justifyContent: 'space-between' }}>
          <button
            onClick={() => navigate(-1)}
            className="next-button"
          >
            ◀ Back
          </button>
          <Feedback />
          <div style={{ display: 'flex', flexDirection: 'column', alignItems: 'flex-end' }}>
            <button
              onClick={navigateToPromptTester}
              className="next-button"
            >
              {currentQuestionIndex === questions.length - 1 ? 'Final Prompt ▶' : 'Next Question ▶'}
            </button>
            {currentQuestionIndex < questions.length - 1 && (
              <button
                onClick={navigateToPromptTester}
                className="skip-link"
                style={{
                  background: 'none',
                  border: 'none',
                  color: '#666',
                  fontSize: '0.8rem',
                  marginTop: '4px',
                  cursor: 'pointer',
                  textDecoration: 'underline',
                  padding: '4px'
                }}
              >
                Skip to Final Prompt
              </button>
            )}
          </div>
        </div>
      </div>
    </div>
  );
};

export default CrayonQuestions; 